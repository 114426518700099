
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { RawLocation } from 'vue-router';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import Form from '@/components/mixins/formChecks';
import { RouteNames, TextfieldInfo } from '@/constants';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { ClickData } from '@/analytics';
import GAnalytics from '@/components/GAnalytics';
import { Team, TeamMember } from '@/types/teams.types';
import { TeamsModule } from '@/store/modules/teams.module';
import { HELP_ROOT_PATH } from '@/constants/help.constants';
import UserCircle from '@/components/UserCircle.vue';

@Component({
  components: {
    FullScreenForm,
    GButton,
    GTextfield,
    GAnalytics,
    UserCircle,
  },
})
export default class NewTeamView extends mixins(Form) {
  private loading = false;
  private projectName = '';
  private projectDescription = '';
  public nameLabel: string = TextfieldInfo.nameLabel;
  public namePlaceholder: string = TextfieldInfo.namePlaceholder;
  public nameValidationMessage: string = TextfieldInfo.nameValidationMessage;
  public descriptionLabel: string = TextfieldInfo.descriptionLabel;
  public descriptionPlaceholder: string = TextfieldInfo.descriptionPlaceholder;
  public descriptionValidationMessage: string = TextfieldInfo.descriptionValidationMessage;
  public tooltipInfo: string = TextfieldInfo.TeamRoleInfoHelp;

  get analyticsData(): ClickData {
    return {
      clickTarget: 'new-project-form-submit-button',
      projectName: this.projectName,
      projectDescription: this.projectDescription,
    };
  }

  back() {
    this.$router.safeBack(this.closeRoute);
  }

  get teamDescription(): string {
    return this.team.teamDescription;
  }

  get teamName(): string {
    return this.team.teamName;
  }

  get teamMembers(): TeamMember[] {
    return this.team.teamMembers;
  }

  get team(): Team {
    return TeamsModule.teamsRecord[this.teamId];
  }

  get teamId(): number {
    return Number(this.$route.params.teamId);
  }

  created() {
    this.$log('Router: ', this.$route.params);
    this.$log('Team', TeamsModule.teams);
  }

  get helpRoute(): RawLocation {
    return `/${HELP_ROOT_PATH}`;
  }

  get closeRoute(): RawLocation {
    return {
      name: RouteNames.ProjectsList,
    };
  }

  get disabled(): boolean {
    return this.loading;
  }

  get submitDisabled(): boolean {
    return !this.projectName || !this.projectDescription;
  }
}
